import Vue from "vue";
import vuetify from "./plugins/vuetify";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import firebase from "firebase";
import "firebase/performance";
import { config } from "./helpers/firebaseConfig";
import DataTable from "v-data-table";
import VeeValidate from "vee-validate";
import Copy from "v-copy";
import DownloadData from "v-download";
import VueMq from "vue-mq";
import "./registerServiceWorker";
import VueAnalytics from "vue-analytics";
import vueDebounce from "vue-debounce";

Vue.use(DataTable);
Vue.use(VeeValidate);
Vue.use(Copy);
Vue.use(DownloadData);
Vue.config.productionTip = false;
Vue.use(VueMq, {
  breakpoints: {
    sm: 680,
    md: 1050,
    lg: 1265,
    xlg: Infinity
  }
});

const isProd = process.env.NODE_ENV === "production";
Vue.use(VueAnalytics, {
  id: "UA-113882241-1",
  router,
  debug: {
    enabled: !isProd,
    sendHitTask: isProd
  }
});

Vue.use(vueDebounce);

/* eslint-disable no-new */
new Vue({
  router,
  store,
  vuetify,
  el: "#app",
  created() {
    if (!firebase.apps.length) {
      firebase.initializeApp(config);
      const perf = firebase.performance();
    }
    firebase.auth().onAuthStateChanged(user => {
      this.$store.dispatch("userChange", user);
    });
  },
  render: h => h(App)
});
