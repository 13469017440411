<template>
  <v-card>
    <v-footer class="footer" padless>
      <v-row justify="center" no-gutters>
        <v-col class="text-center" cols="12">
          &copy; maps4us {{ new Date().getFullYear() }}. All Rights Reserved.
        </v-col>
        <router-link cl to="/privacy">
          Privacy
        </router-link>
        &nbsp;
        <router-link to="/terms">
          Terms
        </router-link>
      </v-row>
    </v-footer>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Footer",
  computed: {
    ...mapGetters(["updating"])
  }
};
</script>

<style>
.footer {
  text-align: center;
  color: #424168 !important;
}

.footer a {
  text-decoration: none;
  color: #424168 !important;
}

.footer a:hover,
.footer a:focus,
.footer a:active,
.footer a.active {
  text-decoration: none;
}
</style>
