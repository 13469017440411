import { RootState } from ".";

export default {
  updating: (state: RootState) => {
    return state.pending;
  },
  mapsLoaded: (state: RootState) => {
    return state.mapsLoaded;
  },
  toast: (state: RootState) => {
    return state.toast;
  },
  notification: (state: RootState) => {
    return state.notification;
  },
  isLoggedIn: (state: RootState) => {
    return state.isLoggedIn;
  },
  displayName: (state: RootState) => {
    return state.user ? state.user.displayName : "";
  },
  maps: (state: RootState) => {
    return state.maps;
  },
  currMap: (state: RootState) => {
    return state.currMap;
  },
  mapTitles: (state: RootState) => {
    return state.maps.map(map => map.title);
  },
  publishedMaps: (state: RootState) => {
    return state.maps
      .filter(map => map.publishedDate !== "nodate")
      .map(map => map.title);
  },
  user: (state: RootState) => {
    return state.user;
  }
};
