import Vue from "vue";
import Router from "vue-router";
import store from "../store";
const Maps = () => import(/* webpackChunkName: "maps" */ "@/pages/Maps");
const Map = () => import(/* webpackChunkName: "map" */ "@/pages/Map");
const Publish = () =>
  import(/* webpackChunkName: "publish" */ "@/pages/Publish");
const MapViewer = () =>
  import(/* webpackChunkName: "mapViewer" */ "@/pages/MapViewer");
const PublishMap = () =>
  import(/* webpackChunkName: "publishMap" */ "@/pages/PublishMap");
const Account = () =>
  import(/* webpackChunkName: "account" */ "@/pages/Account");
const Home = () => import(/* webpackChunkName: "home" */ "@/pages/Home");
const Examples = () =>
  import(/* webpackChunkName: "examples" */ "@/pages/Examples");
const Help = () => import(/* webpackChunkName: "help" */ "@/pages/Help");
const Login = () => import(/* webpackChunkName: "login" */ "@/pages/Login");
const Privacy = () =>
  import(/* webpackChunkName: "privacy" */ "@/pages/Privacy");
const Terms = () => import(/* webpackChunkName: "terms" */ "@/pages/Terms");

Vue.use(Router);

function isLoggedIn(to, from, next) {
  if (!store.getters.isLoggedIn) {
    next("/login");
  } else {
    next();
  }
}

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home
    },
    {
      path: "/help",
      name: "Help",
      component: Help
    },
    {
      path: "/examples",
      name: "Examples",
      component: Examples
    },
    {
      path: "/login",
      name: "Login",
      component: Login
    },
    {
      path: "/privacy",
      name: "Privacy",
      component: Privacy
    },
    {
      path: "/terms",
      name: "Terms",
      component: Terms
    },
    {
      path: "/maps",
      name: "Maps",
      component: Maps,
      beforeEnter: isLoggedIn
    },
    {
      path: "/maps/:title",
      name: "Map",
      component: Map,
      beforeEnter: isLoggedIn
    },
    {
      path: "/account",
      name: "Account",
      component: Account,
      beforeEnter: isLoggedIn
    },

    {
      path: "/publish",
      name: "Publish",
      component: Publish,
      beforeEnter: isLoggedIn
    },
    {
      path: "/mapviewer/:mapId",
      name: "Map viewer",
      component: MapViewer
    },
    {
      path: "/publish/:title",
      name: "Publish Map",
      component: PublishMap,
      beforeEnter: isLoggedIn
    }
  ]
});
