import firebase from "firebase";

type Label = [string, string];

export class Analytics {
  uid: string;

  constructor(uid: string = "") {
    this.uid = uid;
  }
  private labelsToParams(labels: Label[]) {
    let eventParams: { [key: string]: any } = {};
    if (this.uid.length > 0) {
      eventParams["uid"] = this.uid;
    }
    labels.forEach(label => (eventParams[label[0]] = label[1]));
    return eventParams;
  }
  public log(event: string, ...labels: Label[]) {
    let params = {};
    params = this.labelsToParams(labels);
    firebase.analytics().logEvent(event, params);
  }
}
