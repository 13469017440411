interface Toast {
  text: string | string[];
  icon: string;
  indefinite?: boolean;
}

interface ImportRejected {
  count: number;
  markers: string[];
}

export interface ImportResponse {
  imported: number;
  rejected: ImportRejected;
}

export function addMarker(name: string): Toast {
  return {
    text: `${name} added!`,
    icon: `user-plus`
  };
}

export function updateMarker(name: string): Toast {
  return {
    text: `${name} updated!`,
    icon: `pencil-square-o`
  };
}

export function deleteMarker(): Toast {
  return {
    text: `Marker Deleted!`,
    icon: `trash-o`
  };
}

export function updateMap(title: string): Toast {
  return {
    text: `${title} has been updated!`,
    icon: `pencil-square-o`
  };
}

export function deleteMap(title: string): Toast {
  return {
    text: `${title} deleted!`,
    icon: `trash-o`
  };
}

export function publishMap(title: string): Toast {
  return {
    text: `${title} has been published!`,
    icon: `check`
  };
}

export function importData(
  fileName: string,
  error: boolean,
  importResponse: ImportResponse
): Toast {
  if (error) {
    return {
      text: `Error importing ${fileName}!`,
      icon: `exclamation-triangle`
    };
  } else {
    if (importResponse.rejected.count > 0) {
      const rejectedMarkers = importResponse.rejected.markers.map(
        marker => `-${marker}`
      );

      return {
        text: [
          `Processed ${fileName} and imported ${importResponse.imported} markers!`,
          `Rejected:`,
          ...rejectedMarkers
        ],
        icon: `exclamation-triangle`,
        indefinite: true
      };
    } else {
      return {
        text: `Processed ${fileName} and imported ${importResponse.imported} markers!`,
        icon: `check`
      };
    }
  }
}
