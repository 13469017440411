import { RootState, User, Map } from "./index";
import * as toastGenerator from "./toastGenerator";
import { MapService } from "./mapService";
import { ImportResponse } from "./toastGenerator";

export default {
  CHANGE_USER(state: RootState, user: User) {
    state.user = user;
    state.isLoggedIn = state.user ? true : false;
    state.maps = [];
    state.mapService = undefined;
  },
  SET_MAPSERVICE(state: RootState, mapService: MapService) {
    state.mapService = mapService;
  },
  SET_USER_DATA(
    state: RootState,
    [creationDate, lastLoggedIn]: [string, string]
  ) {
    if (state.user) {
      state.user.creationDate = creationDate;
      state.user.lastLoggedIn = lastLoggedIn;
    }
  },
  SET_FIREBASE_UI: (state: RootState, ui: firebaseui.auth.AuthUI) =>
    (state.firebaseUi = ui),
  ADD_MAP: (state: RootState) => (state.pending = true),
  ADD_MAP_DONE: (state: RootState) => (state.pending = false),
  ADD_MARKER: (state: RootState) => (state.pending = true),
  ADD_MARKER_DONE: (state: RootState, name: string) => {
    state.pending = false;
    state.toast = toastGenerator.addMarker(name);
  },
  UPDATE_MARKER: (state: RootState) => (state.pending = true),
  UPDATE_MARKER_DONE: (state: RootState, name: string) => {
    state.pending = false;
    state.toast = toastGenerator.updateMarker(name);
  },
  DELETE_MARKER: (state: RootState) => (state.pending = true),
  DELETE_MARKER_DONE: (state: RootState) => {
    state.pending = false;
    state.toast = toastGenerator.deleteMarker();
  },
  GETTING_MAPS: (state: RootState) => (state.pending = true),
  GETTING_MAPS_DONE(state: RootState, maps: Map[]) {
    state.maps = maps;
    state.mapsLoaded = true;
    state.pending = false;
  },
  GETTING_MAP(state: RootState, title: string) {
    state.pending = true;

    if (state.currMap && state.currMap.title !== title) {
      // unset to force redraw and use transition effect
      state.currMap = undefined;
    }
  },
  GETTING_MAP_DONE(state: RootState, currMap: Map) {
    state.currMap = Object.assign({}, currMap);
    state.pending = false;
  },
  DELETE_MAP: (state: RootState) => (state.pending = true),
  DELETE_MAP_DONE(state: RootState, title: string) {
    state.toast = toastGenerator.deleteMap(title);
    state.currMap = undefined;
    state.pending = false;
  },
  PUBLISH_MAP: (state: RootState) => (state.pending = true),
  PUBLISH_MAP_DONE: (state: RootState, title: string) => {
    state.pending = false;
    state.toast = toastGenerator.publishMap(title);
  },
  UPDATE_MAP: (state: RootState) => (state.pending = true),
  UPDATE_MAP_DONE: (state: RootState, title: string) => {
    state.toast = toastGenerator.updateMap(title);
    state.pending = false;
  },
  CLEAR_CURRENT_MAP: (state: RootState) => (state.currMap = undefined),
  IMPORT: (state: RootState) => (state.pending = true),
  IMPORT_DONE: (
    state: RootState,
    {
      fileName,
      error,
      importResponse
    }: { fileName: string; error: boolean; importResponse: ImportResponse }
  ) => {
    state.pending = false;
    state.toast = toastGenerator.importData(fileName, error, importResponse);
  },
  CLEAR_TOAST: (state: RootState) => (state.toast = undefined),
  SET_NOTIFICATION: (state: RootState, notification: object) =>
    (state.notification = notification)
};
