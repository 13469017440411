import firebase from "firebase";

export const config = {
  apiKey: "AIzaSyDYAAcbLEr_bIJtXEujL_UhRbi2VDTZt6I",
  authDomain: "mapsforall-96ddd.firebaseapp.com",
  databaseURL: "https://mapsforall-96ddd.firebaseio.com",
  projectId: "mapsforall-96ddd",
  storageBucket: "mapsforall-96ddd.appspot.com",
  messagingSenderId: "935840763670",
  appId: "1:935840763670:web:2ca246b87925ba39f48a37",
  measurementId: "G-ZFK3TRWZ8P"
};

export const uiConfig = {
  callbacks: {
    uiShown: function() {
      // The widget is rendered.
      // Hide the loader.
      document.getElementById("loader").style.display = "none";
    }
  },
  // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
  signInFlow: "popup",
  signInSuccessUrl: "/",
  signInOptions: [
    // Leave the lines as is for the providers you want to offer your users.
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID
  ],
  // Terms of service url.
  tosUrl: "https://maps4us.com/terms",
  // Privacy policy url.
  privacyPolicyUrl: "https://maps4us.com/privacy"
};
