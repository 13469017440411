import firebase from "firebase";
import * as firebaseui from "firebaseui";
import { uiConfig } from "../helpers/firebaseConfig";

export function getUi(firebaseUi) {
  let ui = firebaseUi;
  if (!ui) {
    ui = new firebaseui.auth.AuthUI(firebase.auth());
  } else {
    ui.reset();
  }
  ui.start("#firebaseui-auth-container", uiConfig);
  return ui;
}
