
import Vue from "vue";
import navBarContent from "./components/NavBar/NavBarContent.vue";
import loading from "./components/App/Loading.vue";
import toast from "./components/App/Toast.vue";
import notification from "./components/App/Notification.vue";
import mapsFooter from "./components/App/Footer.vue";

export default Vue.extend({
  name: "App",
  components: {
    navBarContent,
    loading,
    toast,
    mapsFooter,
    notification
  }
});
