<template>
  <v-menu
    :nudge-width="100"
    offset-y
    transition="slide-y-transition"
    max-height="300px"
  >
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" text>
        <i :class="icon" />
        <span class="hidden-sm-and-down">&nbsp;{{ title }}</span>
      </v-btn>
    </template>
    <v-list>
      <add-map-modal>
        <v-list-item href="#" slot-scope="{ openDialog }">
          <v-list-item-title @click.stop="openDialog" v-text="'Add new map'" />
        </v-list-item>
      </add-map-modal>
      <v-divider />
      <v-list-item
        v-for="mapTitle in mapTitles"
        :key="mapTitle"
        :to="'/' + page + '/' + mapTitle"
        exact
      >
        <v-list-item-title v-text="mapTitle" />
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
import { mapGetters } from "vuex";
const addMapModal = () =>
  import(/* webpackChunkName: "addMapModal" */ "../AddMapModal.vue");

export default {
  name: "SelectMap",
  props: {
    title: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    page: {
      type: String,
      required: true
    }
  },
  components: {
    addMapModal
  },
  computed: {
    ...mapGetters(["mapTitles"])
  },
  methods: {
    selectMap(mapTitle) {
      this.$emit("selectMap", mapTitle);
    }
  }
};
</script>
