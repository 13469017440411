<template>
  <div v-if="updating" class="busy-indicator">
    <div>
      <i class="fa fa-spinner fa-spin fa-5x fa-fw overlay logo_icon" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "Loading",
  computed: {
    ...mapGetters(["updating"])
  }
};
</script>
<style scoped>
.busy-indicator > div {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 5000;
}
</style>
