
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "Toast",
  data() {
    return {
      snackbar: false
    };
  },
  computed: {
    ...mapGetters(["toast"]),
    timeout() {
      if (this.toast && this.toast.indefinite != null) {
        return 0;
      } else {
        return 2000;
      }
    }
  },

  watch: {
    toast() {
      // check just that it exists
      if (this.toast) {
        this.snackbar = true;
      }
    },
    snackbar() {
      if (!this.snackbar) {
        this.$store.dispatch("clearToast");
      }
    }
  }
});
