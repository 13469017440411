<template>
  <v-toolbar-items
    v-if="$route.path.toLowerCase().indexOf('mapviewer') == -1 || isLoggedIn"
  >
    <v-btn to="/" exact text>
      <i class="fa fa-home" />
      <span class="hidden-sm-and-down">&nbsp;Home</span></v-btn
    >
    <select-map
      v-if="isLoggedIn && mapTitles.length > 0"
      title="Maps"
      icon="fa fa-map-o"
      page="maps"
    />
    <select-map
      v-if="isLoggedIn && mapTitles.length > 0"
      title="Publish"
      icon="fa fa-share-square-o"
      page="publish"
    />
    <v-btn text to="/help">
      <i class="fa fa-question-circle-o" />
      <span class="hidden-sm-and-down">&nbsp;Help</span>
    </v-btn>
    <v-btn text to="/examples">
      <i class="fa fa-comments-o" />
      <span class="hidden-sm-and-down">&nbsp;Examples</span>
    </v-btn>
    <v-menu
      v-if="isLoggedIn"
      :nudge-width="100"
      offset-y
      transition="slide-y-transition"
    >
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" text>
          <i class="fa fa-user-o" />
          <span class="hidden-sm-and-down">&nbsp;{{ displayName }}</span>
        </v-btn>
      </template>
      <v-list>
        <v-list-item to="/account">
          <v-list-item-title v-text="'Account'" />
        </v-list-item>
        <v-list-item @click="logout">
          <v-list-item-title v-text="'Logout'" />
        </v-list-item>
      </v-list>
    </v-menu>
    <v-btn v-else text to="/login" exact>
      <i class="fa fa-sign-in" />
      <span class="hidden-sm-and-down">&nbsp;Login</span>
    </v-btn>
  </v-toolbar-items>
</template>

<script>
import firebase from "firebase";
import { mapGetters } from "vuex";
import selectMap from "./SelectMapNavBar.vue";

export default {
  name: "NavBarContent",
  components: {
    selectMap
  },
  computed: {
    ...mapGetters(["isLoggedIn", "displayName", "mapTitles"])
  },
  methods: {
    logout() {
      firebase.auth().signOut();
      this.$router.push(`/`);
    }
  }
};
</script>
