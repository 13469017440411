import Vue from "vue";
import Vuex from "vuex";

import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

import { MapService } from "./mapService";

Vue.use(Vuex);

export interface User extends firebase.User {
  creationDate: string;
  lastLoggedIn: string;
}

export interface Map {
  title: string;
  id: string;
  publishedDate: string;
}

export interface RootState {
  isLoggedIn: boolean;
  user?: User;
  mapsLoaded: boolean;
  maps: Map[];
  currMap?: Map;
  pending: boolean;
  toast?: object;
  firebaseUi?: firebaseui.auth.AuthUI;
  mapService?: MapService;
  notification?: object;
}

export default new Vuex.Store<RootState>({
  state: {
    isLoggedIn: false,
    user: undefined,
    mapsLoaded: false,
    maps: [],
    currMap: undefined,
    pending: false,
    toast: undefined,
    firebaseUi: undefined,
    mapService: undefined,
    notification: undefined
  },
  actions,
  mutations,
  getters
});
